import React, { lazy, useState } from 'react';
import { Typography, FormControlLabel, Checkbox } from '@mui/material';
import { navigate } from '@reach/router';
import LIB from '../helper/lib';
import ContainerVF from '../element/ContainerVF';
import ButtonVF from '../element/ButtonVF';
import { SERVER_URL } from '../data/app-info';
import userState from '../context/user';

const FormVF = lazy(() =>
    import(/* webpackChunkName: "FormVF" */ '../element/FormVF')
);

let key2 = null;

function Reset() {
    const [sealedEmail, setSealedEmail] = useState();
    const [subscribe, setSubscribe] = useState(true);

    const reset = async (result) => {
        const {
            email: { value: email = null },
            key: { value: key = null },
            password: { value: password = null },
            password2: { value: password2 = null }
        } = result;

        if (!sealedEmail) {
            return {
                success: false,
                message: 'You have not verified your PIN.'
            };
        }

        if (!email) {
            return {
                success: false,
                message: 'Check your email and try again.'
            };
        }

        if (!key || !key2 || key.toString() !== key2.toString()) {
            return {
                success: false,
                message: 'Your PIN did not match. Please try again.'
            };
        }

        if (email !== sealedEmail) {
            return {
                success: false,
                message:
                    'The email address to which the verification key was sent does not match the current address. Please request a new key to continue'
            };
        }

        if (password !== password2) {
            return {
                success: false,
                message: 'Passwords do not match'
            };
        }

        const userData = new FormData();
        userData.append('email', email);
        userData.append('password', password);

        const output = await LIB.getFetch(
            `${SERVER_URL}/update_user_db`,
            userData
        );

        if (subscribe) {
            try {
                const subscribeData = new FormData();
                subscribeData.append('email', email);
    
                LIB.getFetch(
                    `${SERVER_URL}/subscribe`,
                    subscribeData
                );
            }
            catch (e) {
                console.warn('e subscription: ', e);
            }
        }

        if (output && output.success && output.result) {
            navigate('?thank-you-reset');
            userState.setInfo(output.result);
        }

        return { success: true };
    };

    const sendKey = async (data) => {
        const {
            email: { value: email = null }
        } = data;

        if (!email) {
            return {
                success: false,
                messageTop:
                    'We could not send you an email. Please check your email.'
            };
        }

        const keyData = new FormData();
        keyData.append('email', email);

        const output = await LIB.getFetch(`${SERVER_URL}/send_key`, keyData);
        if (output && output.success) {
            key2 = output.key;
            setSealedEmail(email);
            return {
                success: true,
                messageTop:
                    'Your PIN is sent. If you do not receive it within a few minutes, please check your spam folder. Please add support@vizframe.com to your safe senders list, address book, or contact list.'
            };
        }

        return {
            success: false,
            messageTop:
                "We're having trouble sending you an email. Please contact us at support@vizframe.com to expedite a resolution."
        };
    };

    return (
        <>
            <ContainerVF maxWidth='sm'>
                <Typography variant='h2'>Reset Password</Typography>
            </ContainerVF>

            <FormVF
                maxWidth='xs'
                category='Reset'
                buttonText='Reset'
                submitFunction={reset}
                elements={{
                    email: {
                        title: 'Email',
                        value: null,
                        button: {
                            title: 'Send PIN',
                            f: sendKey
                        },
                        type: 'email'
                    },
                    key: {
                        title: 'Enter PIN',
                        exact: 5
                    },
                    password: {
                        title: 'New Password',
                        type: 'password',
                        min: 5
                    },
                    password2: {
                        title: 'Re-enter New Password',
                        type: 'password',
                        min: 5
                    }
                }}
            >
                <ButtonVF
                    size='sm'
                    border={false}
                    variant='secondary'
                    vfstat={{
                        category: 'Account',
                        action: 'Register'
                    }}
                    aria-label='register'
                    onClick={() => {
                        userState.set('type', 'register');
                    }}
                >
                    Not a member? Register
                </ButtonVF>

                <ButtonVF
                    size='sm'
                    variant='secondary'
                    border={false}
                    vfstat={{
                        category: 'Account',
                        action: 'Sign in'
                    }}
                    aria-label='Signin'
                    onClick={() => {
                        userState.set('type', 'signin');
                    }}
                >
                    Already registered? Sign in
                </ButtonVF>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={subscribe}
                            onChange={(e) => {
                                setSubscribe(e.target.checked);
                            }}
                            name='subscribe'
                            color='primary'
                        />
                    }
                    label='Get Promo Codes and the Latest News'
                />
            </FormVF>
        </>
    );
}

export default Reset;
